import React from 'react'

import { MeasuringStrategy, closestCenter } from '@dnd-kit/core'

import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import DragDropContext from 'components/dnd/DragDropContext'
import type { BaseSortable, SortableProps } from 'components/dnd/Sortable/Sortable.types'
import SortableItem from 'components/dnd/SortableItem'

const Sortable = <T extends BaseSortable>({
  id,
  items,
  renderItem,
  disabled,
  ...props
}: SortableProps<T>) => {
  const getItemIds = () => items.map((item) => `${item.id}`)

  const getItemId = (index: number) => `${items[index].id}`

  return (
    <DragDropContext
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement, restrictToVerticalAxis]}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      {...props}>
      <SortableContext
        id={id}
        items={getItemIds()}
        disabled={disabled}
        strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableItem key={getItemId(index)} itemId={getItemId(index)}>
            {({ ...props }) => renderItem({ item, index, ...props })}
          </SortableItem>
        ))}
      </SortableContext>
    </DragDropContext>
  )
}

export default Sortable
